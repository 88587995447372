import React from 'react';
import styled from 'styled-components';
import {Select, Input, Button, Switch} from 'antd';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import {DELIVERY_TYPE} from '../../dictionary';
import getOptions from '../../Utils/getOptions';
import {getStatusSet} from '../../Utils/LogisticUtil';

const TYPE_OPTIONS = getOptions(DELIVERY_TYPE);
const allStatus = {label: '全部', value: 'all'};
const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function Filters({filters, changeFilters, getRecords}) {
  return (
    <Container>
      <InputGroup>
        <p>查找物流單</p>
        <StyledInput
          defaultValue={filters?.search_str}
          placeholder="追蹤單號/訂單編號/購買者"
          onSearch={(str) =>
            changeFilters({search_str: str, ...PAGINATION_INIT})
          }
          allowClear
        />
      </InputGroup>
      <InputGroup>
        <p>寄送方式</p>
        <StyledSelect
          placeholder="選擇寄送方式"
          value={filters.logistics_type}
          onChange={(nextValue) => {
            changeFilters({
              logistics_type: nextValue,
              logistics_status: allStatus.value,
              ...PAGINATION_INIT,
            });
          }}>
          {TYPE_OPTIONS.map((option, idx) => (
            <Select.Option key={idx} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </StyledSelect>
      </InputGroup>
      <InputGroup>
        <p>物流狀態</p>
        <StyledSelect
          placeholder="選擇物流狀態"
          value={filters.logistics_status}
          onChange={(nextValue) => {
            changeFilters({
              logistics_status: nextValue,
              ...PAGINATION_INIT,
            });
          }}>
          {getOptions(getStatusSet({type: filters.logistics_type})).map(
            (option, idx) => (
              <Select.Option key={idx} value={option.value}>
                {option.label}
              </Select.Option>
            ),
          )}
        </StyledSelect>
      </InputGroup>
      <StyledButton
        onClick={getRecords}
        icon={<Refresh color="#1890ff" size={24} style={{marginRight: 8}} />}>
        刷新
      </StyledButton>
      <Actions>
        <div className="switchContainer">
          聯運
          <Switch
            style={{marginLeft: 8}}
            checked={filters.is_areas}
            onChange={(checked) => {
              changeFilters({is_areas: checked});
            }}
          />
        </div>
      </Actions>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: end;
  flex-wrap: wrap;
  padding: 16px;
  column-gap: 16px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  margin-top: 16px;
  .switchContainer {
    display: flex;
    align-items: center;
  }
`;

const InputGroup = styled.div`
  flex: 1;
  p {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #9b9b9b;
  }
`;

const StyledInput = styled(Input.Search)`
  height: 48px;
  &&& {
    .ant-input-affix-wrapper {
      padding: 12px;
    }
    .ant-btn-icon-only {
      height: 48px;
      width: 48px;
    }
    .anticon-search {
      font-size: 20px;
    }
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  &&& {
    .ant-select-selector {
      padding: 12px 16px;
      height: 48px;
    }
    .ant-select-selection-item {
      line-height: 24px;
    }
    .ant-select-arrow {
      right: 16px;
    }
  }
`;

const StyledButton = styled(Button)`
  && {
    display: flex;
    align-items: center;
    width: 92px;
    height: 48px;
    border-radius: 10px;
    border: 2px solid #227fe9;
    background-color: transparent;
    color: #227fe9;
    font-size: 14px;
    font-weight: 500;
  }
`;

const ActionButton = styled(Button)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    background-color: ${(props) => (props.dispatch ? '#227fe9' : '#fff')};
    border: 2px solid #227fe9;
    border-radius: 10px;
    padding: 12px;
    font-size: 16px;
    color: ${(props) => (props.dispatch ? '#fff' : '#227fe9')};
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
`;
